
.toggle {
    -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
}
/* line 66, ../sass/ng-animation.scss */
.toggle.ng-enter {
    opacity: 0;
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
}
/* line 71, ../sass/ng-animation.scss */
.toggle.ng-enter-active {
    opacity: 1;
}
/* line 75, ../sass/ng-animation.scss */
.toggle.ng-leave {
    opacity: 1;
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
}
/* line 80, ../sass/ng-animation.scss */
.toggle.ng-leave-active {
    opacity: 0;
}
/* line 85, ../sass/ng-animation.scss */
.toggle.ng-hide-add {
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
/* line 89, ../sass/ng-animation.scss */
.toggle.ng-hide-add.ng-hide-add-active {
    opacity: 0;
}
/* line 94, ../sass/ng-animation.scss */
.toggle.ng-hide-remove {
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
/* line 99, ../sass/ng-animation.scss */
.toggle.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
}

.scale-fade {
    -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
}
/* line 547, ../sass/ng-animation.scss */
.scale-fade.ng-enter {
    transform: scale(0.7);
    -ms-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 0;
}
/* line 553, ../sass/ng-animation.scss */
.scale-fade.ng-enter-active {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
}
/* line 558, ../sass/ng-animation.scss */
.scale-fade.ng-leave {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
/* line 564, ../sass/ng-animation.scss */
.scale-fade.ng-leave-active {
    transform: scale(0.7);
    -ms-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    opacity: 0;
}
/* line 570, ../sass/ng-animation.scss */
.scale-fade.ng-hide-add {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
/* line 575, ../sass/ng-animation.scss */
.scale-fade.ng-hide-add.ng-hide-add-active {
    transform: scale(0.7);
    -ms-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    opacity: 0;
}
/* line 581, ../sass/ng-animation.scss */
.scale-fade.ng-hide-remove {
    transform: scale(0.7);
    -ms-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
/* line 587, ../sass/ng-animation.scss */
.scale-fade.ng-hide-remove.ng-hide-remove-active {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
}

.md-input-container-style {
    padding-bottom: 0;
    margin-bottom: 0
}